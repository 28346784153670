<template>
	<!-- 首页 底部右侧信息卡片 -->
	<a-card :bordered="false" class="card-info-2 h-full">
		<div class="col-content h-full" :style="{ backgroundImage: `url(${require('@assets/imgs/info-card-2.jpg')})` }">
			<div class="card-content">
				<h5>你会推荐给你的朋友吗？</h5>
				<p>我们是一款Al智能生产力工具，可以快速提高您的工作效率，让您更加轻松地完 成各种任务。同时，我们还是您的贴身工作助手，为您提供更多的便利和帮助。 更重要的是，我们还有丰厚的佣金奖励机制，只要您向好友推荐，并成功注册使用，即可获得相应佣金。所以，不仅可以提升您的工作效率，还可以赚钱，何乐而不为呢？赶快推荐给您的好朋友吧！</p>
			</div>
		</div>
	</a-card>
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>
