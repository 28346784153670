<template>
	<!-- 首页 底部左侧信息卡片 -->
	<a-card :bordered="false" class="card-info">
		<a-row type="flex">
			<a-col class="col-content" :span="24" :xl="12">
				<div class="card-content">
					<h5>AI智能创作平台</h5>
					<p>AI智能艺术平台，拥有丰富多彩的创作功能，包括: Al绘画，语音对话等。我们也正在努力开放更多的平台和功能，让您可以尽情发挥创造力，创作出更加惊艳的作品!而且，我们还提供伪原创功能，让您的创作更加独具特色和个性化。所以，不要错过这个机会。</p>
				</div>
			</a-col>
			<a-col class="col-img" :span="24" :xl="12">
				<div class="card-img-bg">
					<img src="@assets/imgs/info-card-1.jpg" alt="" />
				</div>
			</a-col>
		</a-row>
	</a-card>
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>
