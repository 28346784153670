import { render, staticRenderFns } from "./IndexCase.vue?vue&type=template&id=de6bedd2&scoped=true&"
import script from "./IndexCase.vue?vue&type=script&lang=js&"
export * from "./IndexCase.vue?vue&type=script&lang=js&"
import style0 from "./IndexCase.vue?vue&type=style&index=0&id=de6bedd2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de6bedd2",
  null
  
)

export default component.exports